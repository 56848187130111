import { graphql, HeadProps, PageProps } from "gatsby";
import * as React from "react";

import { BookPreview } from "../components/bookPreview";
import { Hero } from "../components/hero";
import PageLayout from "../components/pageLayout";
import { PageSection } from "../components/pageSection";
import { HeadTemplate } from "../templates/headTemplate";
import { getPaletteFromImageData } from "../util/dynamicThemePalette";

type TemplateProps = PageProps<Queries.AllBooksQuery>;
type HProps = HeadProps & Pick<TemplateProps, "data">;

const BooksPage: React.FC<TemplateProps> = ({ path, data }) => {
  const books = data.articles?.nodes as Queries.bookPreviewFragment[];

  const randomBook = books[Math.floor(Math.random() * books.length)];
  const heroImage = randomBook.image as Queries.SanityRasterImage;

  const dynamicPalette = getPaletteFromImageData(heroImage, "vibrant");

  const previews = books.map((book) => (
    <BookPreview bookData={book} key={book.slug.current} />
  ));

  return (
    <PageLayout themeColor={dynamicPalette}>
      <Hero title="Books" bgImage={heroImage} />
      <PageSection variants={{ display: "grid" }}>{previews}</PageSection>
    </PageLayout>
  );
};

export const query = graphql`
  query AllBooks {
    articles: allSanityBook(sort: { order: ASC, fields: title }) {
      nodes {
        ...bookPreview
      }
    }
  }
`;

export const Head: React.FC<HProps> = () =>
  HeadTemplate({ title: "Latest news" });

export default BooksPage;

import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import classnames from "classnames";
import React from "react";

import { urlFor } from "../../util/urlFor";
import { backgroundImg, hero } from "./hero.css";

type Props = {
  children?: React.ReactNode;
  bgImage?: Queries.SanityRasterImage | string;
  title?: string;
  subtitle?: string | null;
};

const getBackgroundUrl = (bgImage: Partial<Props["bgImage"]>) => {
  if (!bgImage) return null;
  if (typeof bgImage === "string") return bgImage;
  return urlFor(bgImage.file as SanityImageSource).url();
};

export const Hero = ({
  children,
  bgImage,
  title,
  subtitle = undefined,
}: Props) => {
  const backgroundURL = getBackgroundUrl(bgImage);

  const classNames = classnames(hero, {
    small: Boolean(!backgroundURL),
  });

  const inlineVars = assignInlineVars({
    [backgroundImg]: `url("${backgroundURL}")`,
  });

  return (
    <header className={classNames} style={inlineVars}>
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{subtitle}</h2>}
      {children}
    </header>
  );
};
